import React from 'react';
import { graphql } from 'gatsby';

import { IQueryAllResult } from '../models/query-all-result.model';
import { IFaq, IFaqCategory } from '../models/faq.model';
import { useT } from '../hooks/use-translation';
import { getNodes } from '../utils/get-nodes';

import MainLayout from '../layouts/main-layout';
import Hero from '../components/organisms/hero';
import SectionFaqCategories from '../components/organisms/section-faq-categories';
import SectionEcommerceNavigation from '../components/organisms/section-ecommerce-navigation';

interface IFaqPageProps {
    readonly data: {
        allFaq: IQueryAllResult<IFaq>;
        faqCategory: IFaqCategory | null;
    };
}

const FaqAlterpressPage: React.FC<IFaqPageProps> = ({ data }) => {
    const { t } = useT();
    const { allFaq, faqCategory } = data;
    const faqs = getNodes(allFaq).filter(
        (faq) => faqCategory?.categoryId && faq.categoriesIds.includes(faqCategory.categoryId)
    );

    const structuredData = getFaqStructuredData(faqs);

    return (
        <MainLayout
            showFloatingCircles={true}
            structuredData={structuredData}
            showContactForm={true}
            contactFormProps={{
                titleProps: { children: t('faq.alterpress.contact.title') },
                description: t('faq.alterpress.contact.text'),
                buttonProps: { color: 'pink', kind: 'normalDark' },
            }}
        >
            <Hero
                titleProps={{ children: t('faq.alterpress.hero.title'), Tag: 'h1' }}
                color="pink"
                style={{ backgroundColor: 'var(--color-bg-dark)' }}
            />
            <SectionEcommerceNavigation />
            <SectionFaqCategories
                theme="light"
                faqs={faqs}
                faqCategories={faqCategory ? [faqCategory] : []}
                showExpandAllButton={true}
            />
            <SectionEcommerceNavigation />
        </MainLayout>
    );
};

function getFaqStructuredData(faqs: IFaq[]) {
    return JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: faqs.map((faq) => {
            return {
                '@type': 'Question',
                name: faq.question,
                acceptedAnswer: {
                    '@type': 'Answer',
                    text: faq.answer,
                },
            };
        }),
    });
}

export const query = graphql`
    query ($locale: String!) {
        allFaq(filter: { locale: { eq: $locale } }, sort: { fields: sequence, order: ASC }) {
            edges {
                node {
                    ...faqFields
                }
            }
        }
        faqCategory(name: { eq: "alterpress" }) {
            ...faqCategoriesFields
        }
    }
`;

export default FaqAlterpressPage;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';
