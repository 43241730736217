import React, { useState } from 'react';

import {
    categoryBox,
    categoryName,
    categoryHeader,
    buttonExpand,
    icon,
    expanded,
} from './section-faq-categories.module.scss';
import { ITitleProps } from '../atoms/title';
import { IFaq, IFaqCategory } from '../../models/faq.model';
import { useT } from '../../hooks/use-translation';
import { getLessImportantHeading } from '../../utils/get-less-important-heading';

import Section, { ISectionProps } from '../hoc/section';
import Button, { TButtonProps } from '../atoms/button';
import SingleFaq from '../molecules/single-faq';

interface ISectionFaqCategoriesProps {
    className?: string;
    titleProps?: ITitleProps;
    theme?: ISectionProps['theme'];
    faqs: IFaq[];
    faqCategories: IFaqCategory[];
    CategoryNameTag?: React.ElementType;
    buttonProps?: Pick<
        TButtonProps,
        'kind' | 'color' | 'hasArrow' | 'isHoverTransparent' | 'justify'
    >;
    showExpandAllButton?: boolean;
}

const SectionFaqCategories: React.FC<ISectionFaqCategoriesProps> = ({
    className = '',
    titleProps,
    theme = 'dark',
    faqCategories,
    faqs,
    buttonProps,
    showExpandAllButton = false,
}) => {
    const { t } = useT();
    const [areExpanded, setAreExpanded] = useState(false);

    const TitleTag = titleProps?.Tag;
    const CategoryNameTag = (TitleTag && getLessImportantHeading(TitleTag)) || 'h2';
    const QuestionTag = getLessImportantHeading(CategoryNameTag);
    const buttonText = areExpanded
        ? t('section.faq.button.collapse')
        : t('section.faq.button.expand');

    const handleToggleAll = () => {
        setAreExpanded((prev) => !prev);
    };

    return (
        <Section
            className={className}
            theme={theme}
            circleDisplay="none"
            contentDisplay="center"
            titleProps={titleProps}
            gridType="article"
            height="auto"
        >
            {faqCategories.map((category) => {
                const categoryFaqs = faqs.filter((faq) => {
                    return faq.categoriesIds.includes(category.categoryId);
                });
                return (
                    <div
                        className={`${categoryBox} ${areExpanded ? expanded : ''}`}
                        key={`faq-category-list-${category.categoryId}`}
                    >
                        <div className={categoryHeader}>
                            <CategoryNameTag className={categoryName}>
                                {category.name}
                            </CategoryNameTag>
                            {showExpandAllButton && (
                                <Button
                                    className={buttonExpand}
                                    {...buttonProps}
                                    onClick={handleToggleAll}
                                    hasArrow={false}
                                    justify="space-between"
                                >
                                    {buttonText}
                                    <span className={icon} />
                                </Button>
                            )}
                        </div>
                        <ul>
                            {categoryFaqs.map((faq) => {
                                return (
                                    <li key={`faq-item-${faq.faqId}`}>
                                        <SingleFaq
                                            singleFaq={faq}
                                            QuestionTag={QuestionTag}
                                            isOpen={areExpanded}
                                        />
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                );
            })}
        </Section>
    );
};

export default SectionFaqCategories;
